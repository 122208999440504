// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/tmp/build_e1a4963d56280c82e5a5a21c817f54f5/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-address-js": () => import("/tmp/build_e1a4963d56280c82e5a5a21c817f54f5/src/pages/address.js" /* webpackChunkName: "component---src-pages-address-js" */),
  "component---src-pages-careers-js": () => import("/tmp/build_e1a4963d56280c82e5a5a21c817f54f5/src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-index-js": () => import("/tmp/build_e1a4963d56280c82e5a5a21c817f54f5/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-js": () => import("/tmp/build_e1a4963d56280c82e5a5a21c817f54f5/src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacy-index-js": () => import("/tmp/build_e1a4963d56280c82e5a5a21c817f54f5/src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-privacy-piccollageedu-js": () => import("/tmp/build_e1a4963d56280c82e5a5a21c817f54f5/src/pages/privacy/piccollageedu.js" /* webpackChunkName: "component---src-pages-privacy-piccollageedu-js" */),
  "component---src-pages-products-js": () => import("/tmp/build_e1a4963d56280c82e5a5a21c817f54f5/src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-tos-index-js": () => import("/tmp/build_e1a4963d56280c82e5a5a21c817f54f5/src/pages/tos/index.js" /* webpackChunkName: "component---src-pages-tos-index-js" */),
  "component---src-pages-tos-piccollageedu-js": () => import("/tmp/build_e1a4963d56280c82e5a5a21c817f54f5/src/pages/tos/piccollageedu.js" /* webpackChunkName: "component---src-pages-tos-piccollageedu-js" */)
}

